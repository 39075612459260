<template>
<div>

    <v-row align="center" class=" mb-3">
        <v-col cols="12" md="2">
            <v-btn @click="openAddDialog()" color="primary" large class="btn" block>
                <v-icon class="ml-2">add_circle_outline</v-icon>
                <h3> إنشاء شحنة جديدة</h3>
            </v-btn>
        </v-col>
        <v-spacer />

        <v-col cols="12" md="2">
            <v-text-field v-debounce:500ms="getItems" dir="ltr" class="mr-1" v-model="filter.customerPhoneNumber" label="بحث حسب رقم الهاتف" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
        </v-col>

        <v-col cols="12" md="2">
            <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="filter.no" label="بحث حسب رقم الشحنة" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
        </v-col>

        <v-col cols="12" md="2">
            <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="filter.customerName" label="بحث حسب اسم الزبون" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
        </v-col>

        <v-col cols="12" md="2">
            <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="filter.merchantName" label="بحث حسب اسم التاجر" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
        </v-col>

        <v-col cols="12" md="2">
            <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="filter.delegaterName" label="بحث حسب اسم المندوب" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
        </v-col>

        <v-col cols="12" md="2">
            <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="filter.packageContent" label="بحث حسب المحتوى" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
        </v-col>

        <aAutoComplete v-model="filter.warehouseId" :items="warehouses" item-text="name" item-value="id" title="المخزن" @input="getItems()" :cols="12" :md="2" :dense="true" :required="false" :fieldTitle="false" />
        <aAutoComplete v-model="filter.deliveryGovernorateId" :items="$global.state.governorates" item-text="name" item-value="id" title="محافظة التوصيل" @input="getItems()" :cols="12" :md="2" :dense="true" :required="false" :fieldTitle="false" />
        <aAutoComplete v-model="filter.receivingGovernorateId" :items="$global.state.governorates" item-text="name" item-value="id" title="محافظة الاستلام" @input="getItems()" :cols="12" :md="2" :dense="true" :required="false" :fieldTitle="false" />
        <aDatePicker @input="getItems()" v-model="filter.fromDate" title="بحث من تاريخ" :cols="12" :md="2" :dense="true" :required="false" :fieldTitle="false" />
        <aDatePicker @input="getItems()" v-model="filter.toDate" title="بحث الى تاريخ" :cols="12" :md="2" :dense="true" :required="false" :fieldTitle="false" />

        <!-- <v-col cols="12" md="1" class="ml-12">
            <v-btn @click="exportToExcel()" dark color="teal" large class="btn" :loading="loading">
                <v-icon class="ml-4" >fi fi-rr-file-excel</v-icon>
                <h3>تصدير Excel {{this.$route.meta.single}}</h3>
            </v-btn>
        </v-col>

         -->

         

    </v-row>

    <!-- <v-row>
        <v-col cols="12" md="1" class="ml-12">
            <v-btn @click="exportToExcel()" dark color="teal" large class="btn" :loading="loading">
                <v-icon class="ml-4" >fi fi-rr-file-excel</v-icon>
                <h3>تصدير Excel {{this.$route.meta.single}}</h3>
            </v-btn>
        </v-col>

        <v-col cols="12" md="1" class="mr-12">
            <v-btn @click="exportToPdf()" dark color="error" large class="btn" :loading="loading">
                <v-icon class="ml-3">fi fi-rr-file-pdf</v-icon>
                <h3>تصدير pdf {{this.$route.meta.single}}</h3>
            </v-btn>
        </v-col>
    </v-row> -->

    <v-row>
        <v-col cols="6" md="2" v-for="(status,index) in orderStatus" :key="index">
            <v-btn class="btn " @click="()=> { currentIndex == index ? (filter.statusId = '' , getItems(), currentIndex = null) : (filter.statusId = status.id , getItems(), currentIndex = index)}" block depressed large :color="currentIndex == index ? 'primary' : $vuetify.theme.dark ? 'third' : 'white'">

                <v-icon class="ml-1" :color="status.color">{{currentIndex == index ? 'fi fi-rr-circle' : 'fi fi-rr-circle-small'}}</v-icon>
                <h3>{{status.arabicName}}</h3>
                <v-spacer />
                <v-card flat :color="currentIndex == index ? status.color : ''">
                    <v-icon class="pa-2" :color="currentIndex == index ? 'white' : status.color">{{ status.icon }}</v-icon>
                </v-card>
            </v-btn>
        </v-col>
        <v-col cols="3" md="1" >
            <v-btn @click="print()" dark color="error" large class="btn" :loading="loading">
                <v-icon class="ml-3">fi fi-rr-file-pdf</v-icon>
                <h3>تصدير pdf للشحنات</h3>
            </v-btn>
        </v-col>
    </v-row>
    <br />
    <v-card outlined>
        <v-data-table :items-per-page="items.length" :items="items" :headers="headers" :loading="loading" hide-default-footer no-data-text="لا توجد بيانات" loading-text="جاري تحميل البيانات" :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'">

            <template v-slot:item.customerName="{item}">
                <div>{{ item.customerName }}</div>
                <h5 dir="ltr" class="grey--text">{{item.customerPhoneNumber}} || {{item.customerPhoneNumber2}}</h5>
                <!-- <h5 dir="ltr" class="grey--text">{{item.customerPhoneNumber2}}</h5> -->
            </template>

            <template v-slot:item.packageContent="{item}">
                <div>{{ item.packageContent }} <span class="grey--text">( {{item.packageItemsCount}} )</span></div>
            </template>

            <template v-slot:item.receivingGovernorate="{item}">
                <div v-if="item.merchant">{{ item.merchant.fullName }}</div>
                <h5 class="grey--text" v-if="item.receivingGovernorate">{{item.receivingGovernorate.name}} <span v-if="item.receivingArea">( {{item.receivingArea.name}} )</span></h5>
            </template>

            <template v-slot:item.deliveryGovernorate="{item}">
                <div>{{ item.customerName }}</div>
                <h5 dir="ltr" class="grey--text">{{item.customerPhoneNumber}} || {{item.customerPhoneNumber2}}</h5>
                <h5 class="grey--text" v-if="item.deliveryGovernorate">{{item.deliveryGovernorate.name}} <span v-if="item.deliveryArea">( {{item.deliveryArea.name}} )</span></h5>
                <h5 class="grey--text">{{ item.deliveryAddress }}</h5>
            </template>

            <template v-slot:item.no="{item}">
                <v-row justify="center" align="center" class="my-2">
                    <qr-code :size="80" :text="item.no"></qr-code>
                </v-row>
                <div>{{ item.no }}</div>
                <h5 v-if="item.merchant != null" class="primary--text">{{ item.merchant.platform != null ? item.merchant.platform.name : '' }}</h5>
                <h6 class="grey--text">{{item.created.substr(0,10)}} </h6>
            </template>

            <template v-slot:item.status="{item}">
                <div>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip label :color="item.status.color" :disabled="item.status.code == 'received_orders' || item.status.code == 'retrieved' || item.status.code == 'collected' || item.status.code == 'return' || item.status.code =='exchange' || item.status.code === 'return_collected' || item.status.code === 'exchange_collected' || (item.status.code == 'return' && item.recivedAmount == 0)" dark v-bind="attrs" v-on="on">
                                <h4>{{item.status.arabicName}}</h4>
                            </v-chip>
                            <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on">
                                Dropdown
                            </v-btn> -->
                        </template>
                        <v-list>
                            <v-list-item v-for="(status, index) in orderStatus" :key="index" :disabled="status.code=='receipt_delegater' || status.code=='delivery_delegater' || status.code=='collected' || status.code === 'return_collected' || status.code === 'exchange_collected' || status.code =='sorting_site'" @click="changeOrderStatus(status.id, item.id, item.packageCost, status.code, item.sizeCost)">
                                <v-list-item-title class="mx-4">
                                    <h4>
                                        <v-icon class="ml-1" :color="status.color">{{currentIndex == index ? 'fi fi-rr-circle' : 'fi fi-rr-circle-small'}}</v-icon>
                                        {{ status.arabicName }}
                                    </h4>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>

            <template v-slot:item.packageItemsCount="{item}">
                <v-chip>{{item.packageItemsCount}}</v-chip>
            </template>

            <template v-slot:item.recivedAmount="{item}">
                <v-chip label>{{$service.formatCurrency(item.recivedAmount)}}</v-chip>
            </template>

            <template v-slot:item.warehouse.name="{item}">
                <div v-if="item.warehouse != null">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon small class="ml-1" v-on="on">fi fi-rr-garage</v-icon>{{ item.warehouse.name }}
                        </template>
                        <span>المخزن</span>
                    </v-tooltip>
                </div>
                <div v-if="item.delegater != null">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon small class="ml-1" v-on="on">fi fi-rr-truck-side</v-icon>{{ item.delegater.fullName }}
                        </template>
                        <span>المندوب</span>
                    </v-tooltip>
                </div>
                <div v-if="item.delegater == null" class="error--text">المندوب غير محدد</div>
                <div v-if="item.warehouse == null" class="error--text">المخزن غير محدد</div>
            </template>

            <template v-slot:item.packageCost="{item}">
                <!-- قيمة الشحنة -->
                <h4 class="orange--text mt-2">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon color="orange" v-on="on" small class="mx-1">fi fi-rr-box-alt</v-icon>
                        </template>
                        <span>قيمة الشحنة</span>
                    </v-tooltip>
                    {{$service.formatCurrency(item.packageValue)}}
                </h4>
                <!-- كلفة التوصيل -->
                <h4 class="indigo--text" v-if="$store.state.user.userType.id == $global.state.roleType.admin">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon color="indigo" v-on="on" small class="mx-1">fi fi-rr-truck-side</v-icon>
                        </template>
                        <span>اجور التوصيل ( للمندوب ) </span>
                    </v-tooltip>
                    {{$service.formatCurrency(item.deliveryCost)}}
                </h4>
                <!-- اجور المكتب  -->
                <h4 class="cyan--text">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon color="cyan" v-on="on" small class="mx-1">fi fi-rr-garage</v-icon>
                        </template>
                        <span>اجور المكتب ( الاتفاق مع التاجر ) </span>
                    </v-tooltip>
                    {{$service.formatCurrency(item.officeFees)}}
                </h4>
                <!-- الحجم الزائد -->
                <h4 class="purple--text">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon color="purple" v-on="on" small class="mx-1">fi fi-rr-box-open-full</v-icon>
                        </template>
                        <span>الحجم الزائد</span>
                    </v-tooltip>
                    {{$service.formatCurrency(item.sizeCost)}} ( {{ item.extraSize }} )
                </h4>
                <!-- <v-divider class="my-1" /> -->
                <!-- تكلفة الشحنة -->
                <!-- <h4 class="teal--text mb-2">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon color="teal" v-on="on" small class="mx-1">fi fi-rr-file-invoice</v-icon>
                        </template>
                        <span>تكلفة الشحنة</span>
                    </v-tooltip>
                    {{$service.formatCurrency(item.packageValue + item.sizeCost )}}
                </h4> -->

                <!-- المبلغ المستلم -->
                <!-- <h4 class="blue--text mb-2">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon color="blue" v-on="on" small class="mx-1">fi fi-rr-calculator-money</v-icon>
                        </template>
                        <span>المبلغ المستلم</span>
                    </v-tooltip>
                    {{$service.formatCurrency(item.recivedAmount )}}
                </h4> -->

                <!-- <v-chip label>{{$service.formatCurrency(item.packageCost)}}</v-chip> -->
            </template>

            <template v-slot:item.actions="{item}">

                <v-tooltip bottom="" v-if="$store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-delete`)|| $store.state.user.userType.id == $global.state.roleType.admin">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="deleteItem(item.id)" v-on="on" icon :disabled="item.status && (item.status.code === 'received_orders' || item.status.code === 'collected' || item.status.code === 'return_collected' || item.status.code === 'exchange_collected')">
                            <v-icon color="error">
                                fi fi-rr-trash
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>الغاء الشحنة</span>
                </v-tooltip>

                <v-tooltip bottom="">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="openChangeWarehouseDialog(item.id)" v-on="on" icon :disabled="item.status && (item.status.code === 'received_orders' || item.status.code === 'collected' || item.status.code === 'return_collected' || item.status.code === 'exchange_collected')">
                            <v-icon color="indigo">
                                fi fi-rr-garage
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>تغيير المخزن</span>
                </v-tooltip>

                <v-tooltip bottom="" v-if="$store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-update`)|| $store.state.user.userType.id == $global.state.roleType.admin">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="openEditDialog(item)" v-on="on" icon :disabled="item.status && (item.status.code === 'received_orders' || item.status.code === 'collected' || item.status.code === 'return_collected' || item.status.code === 'exchange_collected')">
                            <v-icon color="info">
                                fi fi-rr-edit
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>تعديل</span>
                </v-tooltip>

                <v-tooltip bottom="">
                    <template v-slot:activator="{ on }">
                        <v-btn :to="'/order/'+item.id" v-on="on" icon>
                            <v-icon color="indigo">
                                fi fi-rr-eye
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>عرض التفاصيل</span>
                </v-tooltip>

                <v-tooltip bottom="">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="printOrder(item)" v-on="on" icon>
                            <v-icon color="teal">
                                fi fi-rr-print
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>طباعة الشحنة</span>
                </v-tooltip>

            </template>
        </v-data-table>
        <vue-easy-print class="print-only" ref="easyPrint">
            <v-app>
                <PrintOrder :currentItem="selectedItem" />
            </v-app>
        </vue-easy-print>
    </v-card>
    <Pagination />
    <Dialog />
    <ChangeWarehouseDialog />
    <PaymentDialog />
    <vue-easy-print class="print-only" ref="easyPrint2" >
        <v-app>
            <Print :currentItem="allOrders"/>
        </v-app> 
    </vue-easy-print>
</div>
</template>

<style scoped>
@media print {
    @page {
        size: A4 landscape;
    }

    body {
        width: 297mm; /* A4 width */
        height: 210mm; /* A4 height */
        margin: 0;
    }

    .print-only {
        display: block;
    }
    /* Additional print styles if needed */
}
</style>

<script>
import vueEasyPrint from "vue-easy-print";
import PrintOrder from "./printOrder.vue";
import * as signalR from "@microsoft/signalr";

export default {

    components: {
        Dialog: () => import("./dialog"),
        ChangeWarehouseDialog: () => import("./changeWarehouseDialog"),
        PaymentDialog: () => import("./paymentDialog.vue"),
        vueEasyPrint,
        PrintOrder,
        Print: () => import("./print.vue"),
    },

    data() {
        return {
            connection: null,
            selectedItem: "",
            loading: false,
            items: [],
            allOrders: [],
            orderIds: [],
            orderStatus: [],
            warehouses: [],
            currentIndex: null,
            filter: {
                statusId: '',
                customerPhoneNumber: '',
                no: '',
                customerName: '',
                warehouseId: '',
                deliveryGovernorateId: '',
                receivingGovernorateId: '',
                delegaterName: '',
                merchantName: '',
                packageContent: '',
                fromDate: '',
                toDate: '',
            },
            headers: [{
                    text: "#",
                    value: "no",
                    width: 100,
                    align: 'center'
                },
                {
                    text: "من",
                    value: "receivingGovernorate",
                    width: 150
                },
                {
                    text: "الى",
                    value: "deliveryGovernorate",
                    width: 200,
                    align: 'right'
                },
                {
                    text: "المخزن / المندوب",
                    value: "warehouse.name",
                    width: 200
                },
                // {
                //     text: "المندوب",
                //     value: "delegater.fullName",
                //     width: 200
                // },
                {
                    text: "المحتويات",
                    value: "packageContent",
                    width: 150
                },
                {
                    text: "تكلفة الشحن",
                    value: "packageCost",
                    width: 150
                },
                {
                    text: "المبلغ المستلم",
                    value: "recivedAmount",
                    width: 150
                },
                {
                    text: "حالة الطلب",
                    value: "status",
                    width: 130,
                    align: 'right'
                },
                {
                    text: "ملاحظات",
                    value: "note",
                    width: 130
                },

                {
                    text: "",
                    value: "actions",
                    width: 100
                }
            ],

        }
    },

    created() {

        this.getItems()
        this.getOrderStatus()
        this.getWarehouses()
        setTimeout(() => {
            this.$eventBus.$on(`${this.$route.path}`, () => {
                this.getItems()
            })
        }, 100);
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${this.$config.BASE_URL_PROD}/transactionHub`, {
                withCredentials: true
            })
            .configureLogging(signalR.LogLevel.Information)
            .build();

        this.connection.on("refreshNotification", message => {
            console.log('refreshNotification')
            this.getItems()
        });

        this.connection.onreconnecting(error => {
            console.log('Reconnecting due to:', error);
        });

        this.connection.onreconnected(connectionId => {
            console.log('Reconnected. New connectionId:', connectionId);
        });

        this.connection.onclose(error => {
            console.error('Connection closed due to:', error);
        });

        this.connection.start()
            .then(() => console.log('Connected to SignalR Hub 2'))
            .catch(err => console.error('Error connecting to SignalR Hub:', err.toString()));

    },

    beforeDestroy() {
        this.$eventBus.$off(`${this.$route.path}`)
    },

    methods: {
        async print(){
            this.loading = true; 
            try {
                let res = await this.$http.get(`Order?PageIndex=1
                &StatusId=${this.filter.statusId}&CustomerPhoneNumber=${this.filter.customerPhoneNumber}&No=${this.filter.no}
                &CustomerName=${this.filter.customerName}&WarehouseId=${this.filter.warehouseId}
                &DeliveryGovernorateId=${this.filter.deliveryGovernorateId}&ReceivingGovernorateId=${this.filter.receivingGovernorateId}
                &MerchantName=${this.filter.merchantName}&DelegaterName=${this.filter.delegaterName}&PackageContent=${this.filter.packageContent}
                &FromDate=${this.filter.fromDate}&ToDate=${this.filter.toDate}`)
                this.allOrders = res.data.result
                setTimeout(() => {
                    this.$refs.easyPrint2.print()
                }, 1500);
            } catch (err) {
                console.log(err)
            } finally{
                this.loading = false
            }
        },
        printOrder(item) {
            this.selectedItem = item
            setTimeout(() => {
                this.$refs.easyPrint.print()
            }, 100);
        },
        async getItems() {
            this.loading = true
            try {
                let res = await this.$http.get(`Order?PageIndex=${this.$global.state.filter.pageIndex}&PageSize=${this.$global.state.filter.pageSize}
                &StatusId=${this.filter.statusId}&CustomerPhoneNumber=${this.filter.customerPhoneNumber}&No=${this.filter.no}
                &CustomerName=${this.filter.customerName}&WarehouseId=${this.filter.warehouseId}
                &DeliveryGovernorateId=${this.filter.deliveryGovernorateId}&ReceivingGovernorateId=${this.filter.receivingGovernorateId}
                &MerchantName=${this.filter.merchantName}&DelegaterName=${this.filter.delegaterName}&PackageContent=${this.filter.packageContent}
                &FromDate=${this.filter.fromDate}&ToDate=${this.filter.toDate}`)
                this.items = res.data.result
                this.$global.state.count = res.data.count
                this.$global.state.length = res.data.count == undefined ? 0 : Math.ceil(res.data.count / this.$global.state.filter.pageSize);
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async getAreas() {
            this.loading = true
            try {
                let res = await this.$http.get(`Area?PageIndex=1&PageSize=100&Name=${this.searchArea}`)
                this.areas = res.data.result
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async changeOrderStatus(statusId, orderId, packageCost, status, sizeCost) {
            this.$global.state.loading = true

            try {
                if (status == 'exchange' || status == 'return' || status == 'received_orders') {
                    let data = {
                        statusId: statusId,
                        orderId: orderId,
                        amount: packageCost
                    }
                    this.openPaymentDialog(data)
                } else {
                    this.loading = true
                    try {
                        await this.$http.put(`Order/ChangeStatus?OrderId=${orderId}&NewStatusId=${statusId}`)
                        this.getItems()
                    } catch (err) {
                        console.log(err)
                        this.$service.faild('حدث خطأ')
                        this.$service.faild(JSON.stringify(err.data.result))
                    } finally {
                        this.loading = false
                        this.$global.state.loading = false
                        
                    }
                }
            } catch (err) {
                this.$service.faild('حدث خطأ')
                this.$service.faild(JSON.stringify(err.data.result))
            } finally {
                this.$global.state.loading = false

            }
        },

        async getWarehouses() {
            this.loading = true
            try {
                let res = await this.$http.get(`Warehouse?PageIndex=1&PageSize=1000`)
                this.warehouses = res.data.result
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async getOrderStatus() {
            this.loading = true
            try {
                let res = await this.$http.get(`OrderStatus?PageIndex=1&PageSize=100`)
                this.orderStatus = res.data.result
            } catch (err) {
                console.log(err)
                this.$service.faild('حدث خطأ')
                this.$service.faild(JSON.stringify(err.data.result))
            } finally {
                this.loading = false
            }
        },

        async deleteItem(id) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`${this.$route.meta.endPoint}`, id)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `تم الحذف بنجاح`,
                                type: "success",
                            });
                            this.getItems();
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `حصل خطا اثناء الحذف`,
                                type: "error",
                            });
                        });
                }
            });
        },

        openAddDialog() {
            var item = {
                dialogType: 'add'
            }
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog')
            this.$eventBus.$emit('fetch-data')
        },

        openEditDialog(item) {
            item.dialogType = 'edit'
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog')
            this.$eventBus.$emit('fill-fields')
        },

        openPaymentDialog(item) {
            item.dialogType = 'edit'
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog3')
            this.$eventBus.$emit('fill-fields')
        },

        openChangeWarehouseDialog(orderId) {
            var item = {
                dialogType: 'edit',
                id: orderId
            }
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog2')
            this.$eventBus.$emit('fill-fields')
        },

        async exportToExcel() {
            this.loading = true
            try {
                const res = await this.$http.get('voter/export/excel', {
                    params: {
                        Name: this.searchName,
                        searchUser: this.searchUser,
                        PhoneNumber: this.searchPhoneNumber,
                        AreaId: this.areaId,
                        CenterId: this.centerId,
                        StationId: this.stationId
                    },
                    responseType: 'blob' // to handle the binary data
                });

                // Create a Blob object from the binary data
                const blob = new Blob([res.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                // Create a URL for the Blob object and start download
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Voters.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            } catch (err) {
                console.log(err);
            } finally {
                this.loading = false
            }
        },

        async exportToPdf() {
            this.loading = true
            try {
                const res = await this.$http.get('voter/export/pdf', {
                    params: {
                        Name: this.searchName,
                        searchUser: this.searchUser,
                        PhoneNumber: this.searchPhoneNumber,
                        AreaId: this.areaId,
                        CenterId: this.centerId,
                        StationId: this.stationId
                    },
                    responseType: 'blob' // to handle the binary data
                });

                // Create a Blob object from the binary data
                const blob = new Blob([res.data], {
                    type: 'application/pdf'
                });

                // Create a URL for the Blob object and start download
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Voters.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (err) {
                console.log(err);
            } finally {
                this.loading = false
            }
        },

    },

}
</script>

<template>
    <v-app light>
        <!-- <loading v-if="loading" /> -->
        <v-container light>
            <v-card flat >
                <v-container>
                    <v-row align="center" justify="center" class="mx-4">
                        <v-col>
                            <!-- <v-avatar tile size='150' class="mt-1"> -->
                                <img :src="currentItem.merchant.platform == null ? $config.logo : currentItem.merchant.platform.logoUrl" width="150px"/>
                            <!-- </v-avatar> -->
                        </v-col>
                        <v-col class="text-center">
                            <v-row dir="ltr" justify="center" align="center">
                                <qr-code :size="80" :text="currentItem.no"></qr-code>
                            </v-row>
                        </v-col>
                        <v-col class="text-end">
                            <h3>
                                <strong>
                                    رقم الفاتورة
                                </strong>
                            </h3>
                            <h3>{{ currentItem.no }}</h3>
                            <span class="grey--text">{{ currentItem.created.substr(0,10) }}</span>
                        </v-col>
                        <v-col cols="12">
                            <v-divider />
                        </v-col>
                        <v-col>
                            <h3>
                                <strong>
                                    الزبون:
                                </strong>
                                <h3>
                                    {{ currentItem.customerName }}
                                    <br />
                                    <span style="font-size: 16px">
                                        {{ currentItem.deliveryAddress }}
                                        ({{ currentItem.deliveryGovernorate.name }} - <span v-if="currentItem.deliveryArea">{{ currentItem.deliveryArea.name }}</span>)
                                    </span>
                                    <br />
                                    <span class="grey--text" dir="ltr" style="font-size: 16px">
                                        {{ currentItem.customerPhoneNumber }}
                                        <br />
                                        {{ currentItem.customerPhoneNumber2 }}
                                    </span>
                                </h3>
                            </h3>
                        </v-col>
                        <v-col>
                            <h3>
                                <strong>
                                    التاجر:
                                </strong>
                                <h3>
                                    {{ currentItem.merchant.fullName }}
                                    <!-- <br />
                                    <span style="font-size: 16px">
                                        {{ currentItem.merchant.address }}
                                        ({{ currentItem.merchant.governorate.name }} - {{ currentItem.merchant.area.name }})
                                    </span> -->
                                    <!-- <br />
                                    <span class="grey--text" dir="ltr" style="font-size: 16px">
                                        {{ currentItem.merchant.phoneNumber }}
                                    </span> -->
                                </h3>
                            </h3>
                        </v-col>
                        <v-col>
                            <h3>
                                <strong>
                                    المخزن / المندوب:
                                </strong>
                                <h3>
                                    {{ currentItem.warehouse.name }}
                                    <br />
                                    <span class="grey--text" dir="ltr" style="font-size: 16px" v-if="currentItem.delegater">
                                        {{ currentItem.delegater.phoneNumber }}
                                    </span>
                                </h3>
                            </h3>
                        </v-col>
                        <!-- <v-col cols="12">
                            <v-divider />
                        </v-col> -->
                        <v-col cols="12">
                            <!-- قيمة الشحنة -->
                            <table style="width:60%">
                                <!-- <tr>
                                    <td>
                                        <h4>
                                            <strong>
                                                قيمة الشحنة:
                                            </strong>
                                        </h4>
                                    </td>
                                    <td>
                                        <strong style="font-size: 16px">
                                            {{$service.formatCurrency(currentItem.packageValue)}} IQD
                                        </strong>
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td>
                                        <h4>
                                            <strong>
                                                اجور التوصيل 
                                            </strong>
                                        </h4>
                                    </td>
                                    <td>
                                        <strong style="font-size: 16px">
                                            {{$service.formatCurrency(currentItem.officeFees)}} IQD
                                        </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>
                                            <strong>
                                                الحجم الزائد
                                            </strong>
                                        </h4>
                                    </td>
                                    <td>
                                        <strong style="font-size: 16px">
                                            {{$service.formatCurrency(currentItem.sizeCost)}} ( {{ currentItem.extraSize }} ) IQD
                                        </strong>
                                    </td>
                                </tr>
                                <tr class="text--center">
                                    <v-divider class="my-2 mx-auto " />
                                </tr> -->
                                <tr>
                                    <td>
                                        <h4>
                                            <strong>
                                                تكلفة الشحنة
                                            </strong>
                                        </h4>
                                    </td>
                                    <td>
                                        <strong style="font-size: 16px">
                                            {{$service.formatCurrency(currentItem.packageCost)}} IQD
                                        </strong>
                                    </td>
                                </tr>
                            </table>
                        </v-col>
    
                        <v-col cols="12">
                            <v-divider />
                        </v-col>
                        
                        <v-col cols="12">
                            <h4>
                                ملاحظات: {{ currentItem.note }}
                            </h4>
                        </v-col>
                    </v-row>
                    
                    
                </v-container>
            </v-card>
            
        </v-container>
        <footer class="text-center" style="position: fixed"> 
            <v-row no-gutters class="mx-4" dir="ltr">
                <v-col cols="6" class="text-start">
                    <v-row no-gutters>
                        <v-col cols="12" v-for="(item,index) in $config.phoneNumbers" :key="index">
                            <span dir="ltr" > {{item}} </span> 
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="6" class="text-center">
                    {{$config.address}}
                </v-col>
            </v-row>
        </footer>
    </v-app>
    </template>
    <style scoped>
    footer {
        text-align: center;
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: #f8f9fa; /* Adjust background color as needed */
        padding: 10px;
    }
    </style>
    <script>
    import moment from 'moment';
    export default {
        props: ['currentItem'],
        data() {
            return {
                // currentItem: {},
                loading: false,
                uploading: false,
                comment: ""
            }
        },
    
        created() {
            // this.getItems()
            // console.log(moment().format('LTS'))
        },
    
        methods: {
          formatDate(date) {
            return moment(date).format('h:mm:ss a'); // 'LL' is a format string; you can customize it
          },  
    
            async postComment() {
                this.uploading = true
                let data = {
                    content: this.comment,
                    orderId: this.$route.params.id
                }
                try {
                    await this.$http.post(`Comment`, data)
                    this.comment = ""
                    let res = await this.$http.get(`Comment/${this.$route.params.id}`)
                    this.currentItem.comments = res.data.result
                } catch (err) {
                    console.log(err)
                } finally {
                    this.uploading = false
                }
            },
            async getItems() {
                this.loading = true
                try {
                    let res = await this.$http.get(`Order/${this.$route.params.id}`)
                    this.currentItem = res.data.result
                } catch (err) {
                    console.log(err)
                } finally {
                    this.loading = false
                }
            }
        },
    
    }
    </script>
    